import React from "react";
import Recorder from "./Recorder";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import "./RecorderPage.scss";

export default function RecorderPage({ topic }) {
  let { promptId } = useParams();
  const usingPrompt = promptId !== "none";

  return (
    <section className="section recorder-page">
      <div className="container">
        <div className="return-link-container">
          <Link
            className="prompt-link"
            to={`/topic/${topic.title.toLowerCase()}`}
          >
            <i class="fas fa-arrow-left"></i>&nbsp;Return to Prompts
          </Link>
        </div>
        <h2
          className="subtitle prompt"
          dangerouslySetInnerHTML={{
            __html: usingPrompt
              ? topic.prompts[promptId]
              : "Tell us your stance!",
          }}
        ></h2>
        <Recorder
          filename={topic.title + "-" + promptId}
          topic={topic["_id"]}
          metadata={{ prompt: usingPrompt ? topic.prompts[promptId] : "none" }}
        />
        <div className="tips">
          <b>Tips</b>
          <ul>
            <li>&#8227;&nbsp;Try to keep your recording under ~5 minutes</li>
            <li>&#8227;&nbsp;Provide an opinion, don't just summarize facts</li>
            <br />
            <li>
              Don't worry, you can pause and/or re-record as many times as you'd
              like before submitting!
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
