import React from "react";
import { Link } from "react-router-dom";
import "./PromptsPage.scss";

export default function PromptsPage({ topic }) {
  function renderPrompts() {
    return topic.prompts.map((p, i) => (
      <Link
        key={i}
        className="prompt-link"
        to={`/topic/${topic.title.toLowerCase()}/prompt/${i}`}
        dangerouslySetInnerHTML={{
          __html: p,
        }}
      ></Link>
    ));
  }

  return (
    <section class="section prompts-page">
      <div class="container">
        <h1 class="title">
          {topic.preTitle} {topic.title}
        </h1>
        <h2 class="subtitle">Click on a prompt below to respond to it.</h2>
        {renderPrompts()}
        <Link
          className="prompt-link"
          to={`/topic/${topic.title.toLowerCase()}/prompt/none`}
        >
          <span className="no-prompt-label">No Prompt.</span> Take a stance you
          believe in!
        </Link>
      </div>
    </section>
  );
}
