import React, { useState, useRef } from "react";
import "./Landing.scss";
import ondeckLogo from "./ondeck_white.png";

export default function Landing() {
  const [isWaitlistJoined, setIsWaitlistJoined] = useState(false);
  const [email, setEmail] = useState("");

  const emailInput = useRef(null);

  const api = process.env.REACT_APP_ONDECK_API || "http://localhost:3030/api";

  function addEmailToWaitlist() {
    if (!emailInput.current.reportValidity()) return;
    fetch(api + "/waitlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    }).then(() => {
      setIsWaitlistJoined(true);
    });
  }

  return (
    <>
      <section className="hero is-large landing-hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">
              <img alt="logo" src={ondeckLogo} className="hero-logo" />
              Speakeasy
            </h1>
            <h2 className="subtitle">Let's Talk!</h2>
          </div>
        </div>
        <div className="topics-graphic">
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/business-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/covid-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/education-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/elections-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/fastfood-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/immigration-colorized.jpg')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/police-colorized.png')",
            }}
          ></div>
          <div
            className="topic-strip"
            style={{
              backgroundImage:
                "url('https://ondeck-media.s3.amazonaws.com/topic-images/prison-colorized.jpg')",
            }}
          ></div>
        </div>
      </section>
      <section className="section landing-cta-section">
        <div className="container">
          <div className="landing-cta">Join our waitlist for early access!</div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input
                className="input is-large"
                type="email"
                required
                ref={emailInput}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
              />
            </div>
            <div class="control">
              <button
                class="button is-large is-info"
                onClick={addEmailToWaitlist}
                disabled={isWaitlistJoined}
              >
                {isWaitlistJoined ? "Joined!" : "Join Waitlist"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
