import React from "react";
import ondeckLogo from "./ondeck_blue.png";
import "./LoadingPage.scss";

export default function LoadingPage() {
  return (
    <div className="loading-page">
      <img alt="logo" src={ondeckLogo} className="logo-image" />
    </div>
  );
}
