import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTimer } from "use-timer";
import "./Recorder.scss";
import RecordingSubmissionModal from "./RecordingSubmissionModal";
import AudioRecorder from "audio-recorder-polyfill";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";

AudioRecorder.encoder = mpegEncoder;
AudioRecorder.prototype.mimeType = "audio/mpeg";
window.MediaRecorder = AudioRecorder;

function formatTime(t) {
  let seconds = t % 60;
  seconds = seconds < 10 ? "0" + seconds.toString() : seconds.toString();
  const minutes = Math.floor(t / 60);
  return minutes + ":" + seconds;
}

function makeS3Request(data, file) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(data.url);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        }
      }
    };

    xhr.open("PUT", data.signedUrl);
    xhr.send(file);
  });
}

let recorder;

export default function Recorder({ filename, metadata, topic }) {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioFile, setAudioFile] = useState(false);
  const [submissionModalIsOpen, setSubmissionModalIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { time, start, pause, reset } = useTimer();

  const api = process.env.REACT_APP_ONDECK_API || "http://localhost:3030/api";

  function startRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      recorder = new MediaRecorder(stream);

      recorder.addEventListener("dataavailable", (e) => {
        pause();
        const currentDate = Date.now();
        const file = new File([e.data], filename + "-" + uuidv4(), {
          type: e.data.type,
          lastModified: currentDate,
        });
        setAudioFile(file);
      });

      recorder.start();
      reset();
      start();
      setIsRecording(true);
    });
  }

  function stopRecording() {
    setIsRecording(false);
    setIsPaused(false);
    recorder.stop();
    recorder.stream.getTracks().forEach((i) => i.stop());
  }

  function togglePauseRecording() {
    if (isPaused) {
      recorder.resume();
      start();
      setIsPaused(false);
    } else {
      recorder.pause();
      pause();
      setIsPaused(true);
    }
  }

  function saveAudioToS3(f) {
    return fetch(api + "/sign_s3", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fileName: f.name,
        fileType: f.type,
      }),
    })
      .then((res) => res.json())
      .then((data) => makeS3Request(data, f));
  }

  function submitRecording() {
    setIsSaving(true);
    saveAudioToS3(audioFile).then((url) => {
      fetch(api + "/takes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          audio: url,
          metadata: { ...metadata, name, email },
          topic: topic,
        }),
      }).then(() => {
        setIsSubmitted(true);
        setSubmissionModalIsOpen(false);
        setIsSaving(false);
      });
    });
  }

  if (isSubmitted) {
    return (
      <div className="has-text-success submission-notification">
        <i class="fas fa-check"></i>Submitted!
      </div>
    );
  }

  return (
    <>
      {audioFile ? (
        <>
          <audio controls>
            <source src={URL.createObjectURL(audioFile)} type="audio/mp3" />
          </audio>
          <div className="recording-controls">
            <button
              className="button is-success"
              onClick={() => setSubmissionModalIsOpen(true)}
            >
              Submit Recording
            </button>
            <button
              className="button is-warning"
              onClick={() => {
                reset();
                setAudioFile(undefined);
              }}
            >
              Re-record
            </button>
          </div>
        </>
      ) : (
        <div className="recording-controls">
          <button
            className="button is-danger"
            onClick={startRecording}
            disabled={isRecording}
          >
            <span class="icon">
              <i class="fas fa-circle"></i>
            </span>
            <span>Start Recording</span>
          </button>
          <button
            className={"button " + (isPaused ? "is-info" : "is-warning")}
            onClick={togglePauseRecording}
            disabled={!isRecording}
          >
            <span class="icon">
              <i class={"fas " + (isPaused ? "fa-microphone" : "fa-pause")}></i>
            </span>
            <span>{isPaused ? "Resume" : "Pause"} Recording</span>
          </button>
          <button
            className="button is-success"
            onClick={stopRecording}
            disabled={!isRecording}
          >
            <span class="icon">
              <i class="fas fa-microphone-slash"></i>
            </span>
            <span>End Recording</span>
          </button>
          <div className="timer">{formatTime(time)}</div>
        </div>
      )}

      <RecordingSubmissionModal
        isOpen={submissionModalIsOpen}
        onCancel={() => setSubmissionModalIsOpen(false)}
        name={name}
        email={email}
        onNameChange={(e) => setName(e.target.value)}
        onEmailChange={(e) => setEmail(e.target.value)}
        onSubmit={submitRecording}
        isSaving={isSaving}
      />
    </>
  );
}
