import React, { useRef } from "react";

export default function RecordingSubmissionModal({
  isOpen,
  onSubmit,
  onCancel,
  name,
  onNameChange,
  email,
  onEmailChange,
  isSaving,
}) {
  const emailInput = useRef(null);
  const nameInput = useRef(null);
  return (
    <div className={"modal" + (isOpen ? " is-active" : "")}>
      <div className="modal-background" onClick={onCancel}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Submit Recording</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onCancel}
            disabled={isSaving}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <div className="control">
              <input
                className="input"
                value={name}
                onChange={onNameChange}
                type="text"
                required
                placeholder="Name"
                ref={nameInput}
                disabled={isSaving}
              />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <input
                className="input"
                value={email}
                onChange={onEmailChange}
                type="email"
                required
                placeholder="Email"
                ref={emailInput}
                disabled={isSaving}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            onClick={() =>
              nameInput.current.reportValidity() &&
              emailInput.current.reportValidity() &&
              onSubmit()
            }
            disabled={isSaving}
          >
            Submit!
          </button>
          <button className="button" onClick={onCancel} disabled={isSaving}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
}
