import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Landing";
import Privacy from "./Privacy";
import TopicPage from "./TopicPage";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Switch>
          <Route path="/topic/:topicName" component={TopicPage} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/" component={Landing} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
