import React, { useState, useEffect } from "react";
import { useParams, Route, Link } from "react-router-dom";
import LoadingPage from "./LoadingPage";
import PromptsPage from "./PromptsPage";
import RecorderPage from "./RecorderPage";

import "./TopicPage.scss";

export default function TopicPage() {
  let { topicName } = useParams();
  let [topic, setTopic] = useState(undefined);

  const api = process.env.REACT_APP_ONDECK_API || "http://localhost:3030/api";

  useEffect(() => {
    fetch(api + "/topics?title=" + topicName)
      .then((res) => res.json())
      .then((data) => {
        setTopic(data);
      });
  }, [topicName, api]);

  if (!topic) {
    return <LoadingPage />;
  }
  return (
    <>
      <Link
        to={`/topic/${topicName}`}
        className="hero topic-title"
        style={{ backgroundImage: `url(${topic.image})` }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title">{topic.title}</h1>
          </div>
        </div>
      </Link>
      <Route
        exact
        path="/topic/:topicName"
        render={() => <PromptsPage topic={topic} />}
      />
      <Route
        exact
        path="/topic/:topicName/prompt/:promptId"
        render={() => <RecorderPage topic={topic} />}
      />
    </>
  );
}
